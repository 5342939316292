<template>
  <div>
    <div class="search-default" v-if="advanced">
      <a-select
        v-model="queryItem"
        @change="selectChange"
        class="search-default-label"
      >
        <a-select-option
          v-for="item in queryItems"
          :key="item.value"
          :value="item.value"
        >
          {{ item.label }}
        </a-select-option>
      </a-select>
      <a-input
        v-if="queryItems[index].type === 'input'"
        v-model="queryParam[queryItem]"
        allowClear
        class="search-default-value"
        placeholder="请输入搜索条件"
      />
      <a-select
        v-if="queryItems[index].type === 'select'"
        v-model="queryParam[queryItem]"
        placeholder="请选择搜索条件"
        class="search-default-value"
      >
        <a-select-option
          v-for="opt in filters[queryItems[index].selectOption]"
          :key="opt.value"
          :value="opt.value"
          >{{ opt.label }}</a-select-option
        >
      </a-select>
      <a-date-picker
        v-if="queryItems[index].type === 'date'"
        v-model="queryParam[queryItem]"
        :format="dateFormat"
        :value-format="dateFormatValue"
        class="search-default-value"
      />
      <a-range-picker
        v-if="queryItems[index].type === 'dateRange'"
        :format="dateFormat"
        :value-format="dateFormatValue"
        :ranges="{
          今天: [moment().startOf('day'), moment().endOf('day')],
          当月: [moment().startOf('month'), moment().endOf('month')],
        }"
        @change="changeRange"
        class="search-default-value"
      />
      <!-- :placeholder="['开始日期', '结束日期']" -->
      <a-cascader
        v-if="queryItems[index].type === 'area'"
        :options="cities"
        change-on-select
        expand-trigger="hover"
        @change="onChangeArea"
        class="search-default-value"
        placeholder="请选择地区"
      />
      <a-button @click="fetchData()" class="search-default-button" icon="search"
        >搜索</a-button
      >
      <a-button
        @click="expandChange()"
        v-show="queryItems.length > 0"
        class="search-expand-fold"
        >展开筛选<a-icon type="down"
      /></a-button>
    </div>

    <!-- 展开搜索条件 -->
    <a-row class="search-expand" v-show="expand || !advanced">
      <!-- :span="8" -->
      <a-col :span="6" v-for="item in queryItems" :key="item.value">
        <div v-if="item.type === 'dateRange'" class="tipImg">
          {{ item.label }}
          <span v-if="item.label == '纠正日期'">
            <a-tooltip
              title="默认查询截止今天7天内数据"
              placement="right"
              :overlayStyle="{ width: '230px' }"
            >
              <img src="../../assets/images/visualize/ti_shi.jpg" />
            </a-tooltip>
          </span>
          <span
            v-if="
              item.label == '整洁管理日期查询' ||
              item.label == '报警事件日期查询' ||
              item.label == '人员考勤日期查询'
            "
          >
            <!-- title="默认查询截止今天1天内数据" -->
            <a-tooltip
              title="默认查询当天的数据"
              placement="right"
              :overlayStyle="{ width: '230px' }"
            >
              <img src="../../assets/images/visualize/ti_shi.jpg" />
            </a-tooltip>
          </span>
        </div>
        <!-- <div v-else-if="item.label == '日报查询日期'">{{ item.label }}</div> -->
        <div v-else>{{ item.label }}</div>
        <!-- 输入 -->
        <a-input
          v-if="item.type === 'input'"
          v-model="queryParam[item.value]"
          class="search-expand-value"
          :placeholder="'请输入' + item.label"
        />
        <!-- 选择 -->
        <a-select
          v-if="item.type === 'select' && filters.CompanyList != ''"
          v-model="queryParam[item.value]"
          :placeholder="'请选择' + item.label"
          class="search-expand-value"
          default-value="0"
          @select="SelectChange(queryParam[item.value], item.label)"
        >
          <a-select-option
            v-for="opt in filters[item.selectOption]"
            :key="opt.value"
            :value="opt.value"
            >{{ opt.label }}</a-select-option
          >
        </a-select>
        <!-- 有查询框的下拉框选择  show-search   mode="tags"-->
        <a-select
          v-if="item.type === 'selectSearch' && Object.keys(filters).length > 0"
          v-model="queryParam[item.value]"
          :placeholder="'请选择' + item.label"
          class="search-expand-value"
          mode="tags"
          @select="SelectChange(queryParam[item.value], item.label)"
        >
          <a-select-option
            v-for="opt in filters[item.selectOption]"
            :key="opt.value"
            :value="opt.label"
            >{{ opt.label }}</a-select-option
          >
        </a-select>
        <!--树选择 -->
        <a-tree-select
          v-if="item.type === 'TreeSelect'"
          v-model="TreeSelectValue"
          :placeholder="'请选择' + item.label"
          class="search-expand-value"
          allow-clear
          tree-default-expand-all
          :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
          @change="TreeSelectChange"
          labelInValue
          @select="TreeSelect"
        >
          <a-tree-select-node
            v-for="opt in filters[item.selectOption]"
            :key="opt.value"
            :value="opt.value"
            :title="opt.label"
          >
            <!--:value="opt.value"  v-model="queryParam[item.value][0]" -->
            <!-- :selectable="false" 是否可选 -->
            <a-tree-select-node
              v-for="item in opt.cs_data"
              :key="item.cs_id"
              :value="item.cs_id"
              :title="item.cs_name"
            >
              <!--:value="item.cs_id"  v-model="queryParam[item.value][1]" -->
            </a-tree-select-node>
          </a-tree-select-node>
        </a-tree-select>

        <!-- 日期 -->
        <a-date-picker
          v-if="item.type === 'date'"
          v-model="queryParam[item.value]"
          :format="dateFormat"
          :value-format="dateFormatValue"
          class="search-expand-value"
        />
        <!-- <a-range-picker
          v-if="item.type === 'dateRange'"
          :format="dateFormat"
          v-model="queryParam[item.value]"
          :value-format="dateFormatValue"
          :ranges="{
            今天: [moment().startOf('day'), moment().endOf('day')],
            当月: [moment().startOf('month'), moment().endOf('month')],
          }"
          @change="changeRange(item.value, $event)"
          @openChange="openChange"
          class="search-expand-value"
        /> -->
        <span v-if="item.type === 'dateRange00'" class="dateRange_display">
          <span class="dateRangeBTNName">
            <a-button
              value="small"
              @click="ChangePanel('year')"
              class="dateRangeBTN"
              >年</a-button
            ><a-button
              value="small"
              @click="ChangePanel('month')"
              class="dateRangeBTN"
              >月</a-button
            ><a-button
              value="small"
              @click="ChangePanel('date')"
              class="dateRangeBTN"
              >日</a-button
            >
          </span>
          <a-range-picker
            v-if="item.type === 'dateRange'"
            :format="dateFormat"
            v-model="queryParam[item.value]"
            :value-format="dateFormatValue"
            :ranges="{
              今天: [moment().startOf('day'), moment().endOf('day')],
              当月: [moment().startOf('month'), moment().endOf('month')],
            }"
            :mode="mode"
            @change="changeRange(item.value, $event)"
            @openChange="openChange"
            class="search-expand-value dateRangeName"
          />
        </span>
        <!-- 日期组件改版 -->
        <div class="SiteNeat_date" v-if="item.type === 'dateRange'">
          <span class="dateRangeBTNName">
            <a-button
              value="small"
              @click="ChangePanel('month')"
              class="dateRangeBTN"
              v-if="item.label == '日报日期查询' ? false : true"
              >月</a-button
            >
            <a-button
              value="small"
              @click="ChangePanel('week')"
              class="dateRangeBTN"
              v-if="item.label == '日报日期查询' ? false : true"
              >周</a-button
            >
            <a-button
              value="small"
              @click="ChangePanel('date')"
              class="dateRangeBTN"
              v-if="item.label == '日报日期查询' ? false : true"
              >日</a-button
            >
          </span>
          <!-- 日 -->
          <div class="datePick_out" v-show="dateShow">
            <a-date-picker
              class="start_datePicker"
              v-if="item.type === 'dateRange'"
              v-model="queryParam[item.value][0]"
              :value-format="dateFormatValue"
              format="YYYY-MM-DD"
              :ranges="{
                今天: [moment().startOf('day'), moment().endOf('day')],
                当月: [moment().startOf('month'), moment().endOf('month')],
              }"
              @change="changeRange_start(item.value, $event)"
              @openChange="handleStartOpenChange"
              :disabledDate="disabledDate_dataEnd"
            /><span v-if="item.type === 'dateRange'" class="dateRange_line"
              >~</span
            >
            <a-date-picker
              class="start_datePicker"
              v-if="item.type === 'dateRange'"
              v-model="queryParam[item.value][1]"
              :value-format="dateFormatValue"
              format="YYYY-MM-DD"
              :ranges="{
                今天: [moment().startOf('day'), moment().endOf('day')],
                当月: [moment().startOf('month'), moment().endOf('month')],
              }"
              @change="changeRange_end(item.value, $event)"
              :disabledDate="disabledDate_dataEnd"
            />
          </div>
          <!-- 周 -->
          <div class="datePick_out" v-show="weekShow">
            <a-week-picker
              placeholder="请选择周"
              @change="changeRangeWeek_start(item.value, $event)"
              :disabledDate="disabledDate_weekEnd"
            /><span v-if="item.type === 'dateRange'" class="dateRange_line"
              >~</span
            >
            <a-week-picker
              placeholder="请选择周"
              @change="changeRangeWeek_end(item.value, $event)"
              :disabledDate="disabledDate_weekEnd"
            />
          </div>
          <!-- 月 -->
          <div class="datePick_out" v-show="monthShow">
            <a-month-picker
              placeholder="请选择月"
              @change="changeRangeMonth_start(item.value, $event)"
              :disabledDate="disabledDate_monthEnd"
            /><span v-if="item.type === 'dateRange'" class="dateRange_line"
              >~</span
            >
            <a-month-picker
              placeholder="请选择月"
              @change="changeRangeMonth_end(item.value, $event)"
              :disabledDate="disabledDate_monthEnd"
            />
          </div>
        </div>
        <!-- 日期组件-单个查询 -->
        <div class="SiteNeat_date" v-if="item.type === 'dateOnly'">
          <span class="dateRangeBTNName">
            <a-button
              value="small"
              @click="ChangePanel('month')"
              class="dateRangeBTN"
              >月</a-button
            >
            <a-button
              value="small"
              @click="ChangePanel('week')"
              class="dateRangeBTN"
              >周</a-button
            >
            <a-button
              value="small"
              @click="ChangePanel('date')"
              class="dateRangeBTN"
              >日</a-button
            >
          </span>
          <!-- 日 -->
          <div class="datePick_out" v-if="dateShow">
            <a-date-picker
              class="start_datePicker_only"
              v-if="item.type === 'dateOnly'"
              v-model="queryParam[item.value]"
              :value-format="dateFormatValue"
              format="YYYY-MM-DD"
              @change="changeRange_Only(item.value, $event)"
              @openChange="handleStartOpenChange"
              :disabledDate="disabledDate_dataOnly"
            />
          </div>
          <!-- 周 -->
          <div class="datePick_out" v-if="weekShow">
            <a-week-picker
              placeholder="请选择周"
              v-model="queryParam[item.value]"
              class="start_datePicker_only"
              :value-format="dateFormatValue"
              @change="changeRangeWeek_Only(item.value, $event)"
              :disabled-date="disabledDate_weekOnly"
            />
          </div>
          <!-- 月 -->
          <div class="datePick_out" v-if="monthShow">
            <a-month-picker
              placeholder="请选择月"
              class="start_datePicker_only"
              :value-format="dateFormatValue"
              @change="changeRangeMonth_Only(item.value, $event)"
              :disabled-date="disabledDate_monthOnly"
            />
          </div>
        </div>
        <!-- 日期组件-单个查询-不包含日 -->
        <div class="SiteNeat_date" v-if="item.type === 'dateOnlynoDay'">
          <span class="dateRangeBTNName">
            <a-button
              value="small"
              @click="ChangePanel('month')"
              class="dateRangeBTN"
              >月</a-button
            >
            <a-button
              value="small"
              @click="ChangePanel('week')"
              class="dateRangeBTN"
              >周</a-button
            >
          </span>
          <!-- 周 -->
          <div class="datePick_out" v-if="dateShow || weekShow">
            <a-week-picker
              placeholder="请选择周"
              :value-format="dateFormatValue"
              v-model="queryParam[item.value]"
              class="start_datePicker_only"
              @change="changeRangeWeek_Only(item.value, $event)"
              :disabled-date="disabledDate_weekOnly"
            />
          </div>
          <!-- 月 -->
          <div class="datePick_out" v-if="monthShow">
            <a-month-picker
              placeholder="请选择月"
              :value-format="dateFormatValue"
              class="start_datePicker_only"
              @change="changeRangeMonth_Only(item.value, $event)"
              :disabled-date="disabledDate_monthOnly"
            />
          </div>
        </div>
        <!-- 地区选择 -->
        <a-cascader
          v-if="item.type === 'area'"
          :options="cities"
          change-on-select
          expand-trigger="hover"
          @change="onChangeArea"
          placeholder="请选择地区"
          class="search-expand-value"
        />
      </a-col>
      <!-- 按钮 :span="8"-->
      <a-col :span="6">
        <div class="BTNFlex">
          <a-button
            type="primary"
            :class="{ 'search-expand-button': advanced || special }"
            @click="fetchData()"
            >查询</a-button
          >
          <a @click="resetData()" class="search-reset-button">重置</a>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { GetArea } from "@/utils/cities";
import { GetWorkerType, GetWorkerPost } from "@/api/worker";
import { SelectOptions } from "@/config/SelectOptions.js";
import { GetCompanies, GetCompaniesStore } from "@/api/device";
import moment from "moment";
import {
  MaterialTypeSearch,
  MaterialBrandSearch,
  GetProjectTypeList,
} from "@/api/apiJF/template";
const MAXSearch = 4;
export default {
  name: "Back",
  props: {
    queryItems: {
      type: Array,
    },
    SiteNeat: {
      // type: String,
      // default: "",
    },
    AlarmEvent: {
      type: String,
    },
    e_time: {
      type: Object,
      e_time_start: "",
      e_time_end: "",
    },
    Attention_bc_name: {
      type: String,
    },
    devicesList_BcCs_id: {
      type: Array,
    },
    bc_id: {
      type: String,
    },
    bc_name: {
      type: String,
    },
  },
  data() {
    this.MAXSearch = MAXSearch;
    return {
      advanced: false,
      special: false,
      queryItem: this.queryItems[0].value,
      index: 0,
      selectOption: "",
      expand: false,
      queryParam: {
        // ws_status: "1", //工地状态
        // d_status: "1", //设备状态
        // ws_bind_status: "2", //绑定状态
        // e_events: "",
        bc_name: "",
        bc_id: "",
        cs_id: "",
        // BcCs_id: [6, ""],
        // e_time_start: "",
        // e_time_end: "",
        // eventworker_type: "1",
      },
      cities: [],
      dateFormat: "YYYY/MM/DD",
      dateFormatValue: "X",
      defaultValueStart: "",
      defaultValueEnd: "",
      defaultValueStartNeat: "",
      dateFormatNeat: "",
      isFist: "YES",
      endOpen: false,
      startOpen: false,
      startValue: null,
      endValue: null,
      showAlert: false,
      treeExpandedKeys: [],
      value: undefined,
      // mode: ["day", "day"],
      mode: "date",
      dateShow: true,
      weekShow: false,
      monthShow: false,
      treeSelectValue: {},
      valueList: [],
      bc_idValue: "",
      cs_idValue: "",
      valueLabel: "",
      selectValue: {},
      TreeSelectValue: { label: "全部", value: "" },
      filters: { TprojectList: [] },
      CompanyBcIds: "",
    };
  },
  created() {},
  mounted() {
    this.filters = SelectOptions;
    if (this.queryItems[0].type === "area") {
      GetArea().then((res) => {
        this.cities = res.data;
      });
    }
    if (this.queryItems.some((el) => el.selectOption == "WorkType")) {
      GetWorkerType().then((res) => {
        // console.log(res);
        this.filters.WorkType.length = 0;
        this.filters.WorkType.push({ value: "", label: "全部" });
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          this.filters.WorkType.push({
            value: element.type_id,
            label: element.type_name,
          });
        }
      });
    }
    if (this.queryItems.some((el) => el.selectOption == "WorkPost")) {
      GetWorkerPost().then((res) => {
        // console.log(res);
        this.filters.WorkPost.length = 0;
        this.filters.WorkPost.push({ value: "", label: "全部" });
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          this.filters.WorkPost.push({
            value: element.post_id,
            label: element.post_name,
          });
        }
      });
    }
    if (this.queryItems.some((el) => el.selectOption == "CompanyList")) {
      GetCompanies().then((res) => {
        // console.log(res);
        // this.filters.CompanyList.length = 0;
        this.filters.CompanyList = [];

        this.filters.CompanyList.push({ value: "", label: "全部" });
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          this.filters.CompanyList.push({
            value: element.bc_id,
            label: element.bc_name,
          });
        }
      });
    }
    if (this.queryItems.some((el) => el.selectOption == "CompanyListUnAll")) {
      GetCompanies().then((res) => {
        // console.log(res);
        // this.filters.CompanyList.length = 0;
        this.filters.CompanyListUnAll = [];
        let idList = [];
        res.data.forEach((el) => {
          idList.push(el.bc_id);
        });
        this.filters.CompanyListUnAll.push({
          value: idList.toString(),
          label: "全部",
        });
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          this.filters.CompanyListUnAll.push({
            value: element.bc_id,
            label: element.bc_name,
          });
        }
      });
    }
    if (this.queryItems.some((el) => el.selectOption == "CompanystoreList")) {
      let order = ["bc_id", "bc_name", "cs_data"];
      const param = {
        bc_id: "",
        bc_name: "",
        cs_data: "",
      };
      const params = {
        ...param,
        verify: this.$verify(param, order),
      };
      GetCompaniesStore()
        .then((res) => {
          // console.log(res);
          // this.filters.CompanystoreList.length = 0;
          this.filters.CompanystoreList = [];
          this.filters.CompanystoreList.push({
            value: "",
            label: "全部",
            cs_data: [],
          });

          this.treeData = res.data;
          for (let index = 0; index < res.data.length; index++) {
            const element = res.data[index];
            this.filters.CompanystoreList.push({
              value: element.bc_id,
              label: element.bc_name,
              cs_data: element.cs_data,
            });
          }
          // console.log(this.filters.CompanystoreList);
        })
        .then(() => {
          this.valueList = [];
          this.treeData.forEach((el) => {
            this.valueList.push({ label: el.bc_name, value: el.bc_id });
          });
        });
    }
    if (this.queryItems.some((el) => el.selectOption == "MaterialBrand")) {
      MaterialBrandSearch().then((res) => {
        this.filters.MaterialBrand = [];
        this.filters.MaterialBrand.push({
          value: "",
          label: "全部",
        });
        res.data.forEach((item) => {
          this.filters.MaterialBrand.push({
            value: item.value,
            label: item.key,
          });
        });
        console.log(" 材料品牌下拉框数据", this.filters.MaterialBrand);
      });
    }
    if (this.queryItems.some((el) => el.selectOption == "MaterialType")) {
      MaterialTypeSearch().then((res) => {
        this.filters.MaterialType = [];
        this.filters.MaterialType.push({
          value: "",
          label: "全部",
        });
        res.data.forEach((item) => {
          if (item.value != null) {
            this.filters.MaterialType.push({
              value: item.value,
              label: item.key,
            });
          }
        });
        console.log("  this.filters.MaterialType", this.filters.MaterialType);
      });
    }
    if (this.queryItems.some((el) => el.selectOption == "TprojectList")) {
      let CompanyListid = [];
      GetCompanies()
        .then((res) => {
          res.data.forEach((el) => {
            CompanyListid.push(el.bc_id);
          });
        })
        .then(() => {
          this.CompanyBcIds = CompanyListid.toString();
          let queryParam = {
            ptBcIds: CompanyListid.toString(),
          };
          let orderParam = ["ptBcIds"];
          const param = Object.assign({}, queryParam);
          const requestParameters = this.$order(param, orderParam);
          const params = {
            ...requestParameters,
            verify: this.$verify(requestParameters, orderParam),
          };
          GetProjectTypeList(params).then((res) => {
            // console.log("施工项", res.data);
            this.filters.TprojectList = [];
            // this.filters.TprojectList.push({
            //   value: "",
            //   label: "全部",
            // });
            res.data.forEach((item) => {
              this.filters.TprojectList.push({
                value: item.ptId,
                label: item.ptName,
              });
              if (item.children.length > 0) {
                item.children.forEach((el) => {
                  this.filters.TprojectList.push({
                    value: el.ptId,
                    label: el.ptName,
                  });
                });
              }
            });
            // console.log("施工项", this.filters.TprojectList);
          });
        });
    }
    // 从看板跳过来时传的值

    setTimeout(() => {
      if (this.SiteNeat != "" && this.SiteNeat) {
        this.queryParam.e_events = this.SiteNeat;
        this.filters.CompanyList.forEach((item) => {
          if (item.value == this.Attention_bc_name) {
            this.queryParam.bc_name = item.value;
          }
        });
      } else if (this.AlarmEvent != "" && this.AlarmEvent) {
        this.queryParam.e_events = this.AlarmEvent;
        // if(this.Attention_bc_name==this.filters.CompanyList )
        this.filters.CompanyList.forEach((item) => {
          if (item.value == this.Attention_bc_name) {
            this.queryParam.bc_name = item.value;
          }
        });
      } else {
        // this.queryParam.e_events = "";
        // this.queryParam.bc_name = "";
      }
      // 设备列表传递设备所属分公司默认值
      if (this.devicesList_BcCs_id) {
        if (this.devicesList_BcCs_id != "") {
          this.filters.CompanystoreList.forEach((item) => {
            if (this.devicesList_BcCs_id[0] != "-1") {
              if (item.value == this.devicesList_BcCs_id[0]) {
                // this.queryParam.bc_id = item.value;
                this.TreeSelectValue = { label: item.label, value: item.value };
                // console.log(this.queryParam);
              }
            } else if (this.devicesList_BcCs_id[0] == "-1") {
              item.cs_data.forEach((el) => {
                if (el.cs_id == this.devicesList_BcCs_id[1]) {
                  this.TreeSelectValue = {
                    label: el.cs_name,
                    value: el.cs_id,
                  };
                }
              });
            }
          });
        }
      }
      //工地形象统计-按人员统计  分公司查询跳转添加条件
      this.filters.CompanyList.forEach((item) => {
        if (item.value == this.bc_id || item.value == this.bc_name) {
          this.queryParam.bc_name = item.value;
          this.queryParam.bc_id = item.value;
        }
      });
    }, 200);
    this.initParams();
    this.advanced = this.queryItems.length > this.MAXSearch;
    this.special = this.queryItems.length < this.MAXSearch;
    this.queryItems.forEach((item) => {
      if (item.type == "dateRange") {
        this.startValue = this.queryParam[item.value][0];
        this.endValue = this.queryParam[item.value][1];
        if (this.startValue) {
          let range = this.queryItems.filter((el) => el.value === item.value);
          let start = range[0].range[0];
          this.queryParam[start] = this.startValue.format("X");
          let end = range[0].range[1];
          this.queryParam[end] = this.endValue.format("X");
        }
      }
      if (item.type == "dateOnly" || item.type == "dateOnlynoDay") {
        let range = this.queryItems.filter((el) => el.value === item.value);
        let value = range[0].value;
        this.queryParam[value] =
          this.queryParam[value] == ""
            ? ""
            : this.queryParam[value].format("X");
        // console.log("value", this.queryParam[value]);
      }
    });
    setTimeout(() => {
      GetCompanies().then((res) => {
        // console.log(res);
        // this.filters.CompanyList.length = 0;
        this.filters.CompanyList = [];
        this.filters.CompanyList.push({ value: "", label: "全部" });
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          this.filters.CompanyList.push({
            value: element.bc_id,
            label: element.bc_name,
          });
        }
      });
    }, 100);
  },
  watch: {},
  methods: {
    moment,
    //原来封装
    initParams() {
      this.queryItems.forEach((el) => {
        if (!this.queryParam.hasOwnProperty(el.value)) {
          if (el.type == "dateRange") {
            // console.log(el);
            this.$set(
              this.queryParam,
              el.value,
              el.defaultValue ? el.defaultValue : ""
            );
          } else if (el.type !== "dateRange") {
            // this.queryParam[el?.range[0]] = el?.defaultTime[0];
            // this.queryParam[el?.range[1]] = el?.defaultTime[1];
            this.$set(
              this.queryParam,
              el.value,
              el.defaultValue ? el.defaultValue : ""
            );
          }
        }
      });
    },
    selectChange(value) {
      this.queryItems.forEach((ele, index) => {
        if (ele.value === value) {
          this.index = index;
          this.selectOption = ele.selectOption;
          return;
        }
      });
    },
    expandChange() {
      this.expand = !this.expand;
    },
    fetchData() {
      this.feedback();
      this.$emit("fetchData", this.queryParam);
      // 点击搜索按钮，控制地图页面图标弹出框关闭
      this.$emit("infoWindowClose");
      this.$emit("valueLabel", this.valueLabel);
      // 关闭展开筛选
      this.expand = false;
    },
    feedback() {
      this.queryItems.forEach((el) => {
        if (!this.queryParam.hasOwnProperty(el.value)) {
          this.queryParam[el.value] = this.queryParam[el.value]
            ? this.queryParam[el.value]
            : "";
        }
      });
    },
    // 重置按钮
    resetData() {
      this.queryItems.forEach((el) => {
        // this.queryParam[el.value] = "";
        if (el.range) {
          this.queryParam[el.value] = ["", ""];
          let range0 = el.range[0];
          let range1 = el.range[1];
          this.queryParam[range0] = "";
          this.queryParam[range1] = "";
          this.startValue = null;
          this.endValue = null;
        } else {
          this.queryParam[el.value] = "";
        }
      });
      this.$emit("fetchData", this.queryParam);
    },
    onChangeArea(value) {
      this.queryParam.ws_area = value[value.length - 1];
    },
    changeRange(type, dates) {
      // this.isFist = "YES";
      // this.openChange(true);
      // console.log(type, dates);

      let range = this.queryItems.filter((el) => el.value === type);
      let start = range[0].range[0];
      let end = range[0].range[1];
      this.queryParam[start] = dates[0];
      this.queryParam[end] = dates[1];

      this.isFist = "NO";
    },
    changeRange_start(type, dates) {
      // dates = Date.parse(dates) / 1000;
      dates = dates * 1;
      // console.log(type, this.$Format(dates));
      // console.log(this.$Format(parseInt(dates / 1000)));
      let range = this.queryItems.filter((el) => el.value === type);
      let start = range[0].range[0];
      this.queryParam[start] = dates;
      this.isFist = "NO";
      this.$emit("getDatesStart", dates);
    },
    changeRange_end(type, dates) {
      // dates = Date.parse(dates) / 1000;
      dates = dates * 1;
      let range = this.queryItems.filter((el) => el.value === type);
      let end = range[0].range[1];
      this.queryParam[end] = dates;
      // console.log(this.queryParam[end]);
      this.isFist = "NO";
      this.$emit("getDatesEnd", dates);
    },

    changeRangeWeek_start(type, dates) {
      let range = this.queryItems.filter((el) => el.value === type);
      let start = range[0].range[0];
      this.queryParam[start] = this.$beginOfWeek(dates);
      this.isFist = "NO";
      this.$emit("getDatesStart", Date.parse(dates) / 1000);
    },

    changeRangeWeek_end(type, dates) {
      let range = this.queryItems.filter((el) => el.value === type);
      let end = range[0].range[1];
      this.queryParam[end] = this.$endOfWeek(dates);
      this.isFist = "NO";
      this.$emit("getDatesEnd", Date.parse(dates) / 1000);
    },

    changeRangeMonth_start(type, dates) {
      dates = Date.parse(dates) / 1000;
      dates = dates * 1;

      let range = this.queryItems.filter((el) => el.value === type);
      let start = range[0].range[0];
      this.queryParam[start] = this.$beginOfMonth(dates * 1000);

      this.isFist = "NO";
      this.$emit("getDatesStart", dates);
    },
    changeRangeMonth_end(type, dates) {
      dates = Date.parse(dates) / 1000;
      dates = dates * 1;
      let range = this.queryItems.filter((el) => el.value === type);
      let end = range[0].range[1];
      this.queryParam[end] = this.$endOfMonth(dates * 1000);
      this.isFist = "NO";
      this.$emit("getDatesEnd", dates);
    },
    // 单个日期查询赋值
    changeRange_Only(type, dates) {
      // dates = dates * 1;
      console.log(type, dates);
      // console.log(this.queryParam[type]);
      this.queryParam[type] = dates;
      this.$emit("getDates", dates);
    },
    changeRangeWeek_Only(type, dates) {
      this.queryParam[type] = dates;
      this.$emit("getDates", dates);
    },
    changeRangeMonth_Only(type, dates) {
      this.queryParam[type] = dates;
      this.$emit("getDates", dates);
    },
    // 打开面板时，调用方法，显示前一月和当前月的日期
    openChange(status) {
      // console.log(status); //true  false
      if (this.isFist == "YES" && status) {
        setTimeout(() => {
          const pre = document.getElementsByClassName(
            "ant-calendar-prev-month-btn"
          );
          pre[0].click();
        });
        setTimeout(() => {
          const next = document.getElementsByClassName(
            "ant-calendar-prev-month-btn"
          );
          if (next[1]) {
            next[1].click();
          }
        }, 10);
      }
      if (!status) {
        this.isFist = "NO";
      }
    },
    handleStartOpenChange(open) {
      console.log(open);
      if (!open) {
        // this.endOpen = true;
      }
      // this.openChange(true);
      this.isFist = "NO";
    },
    // 切换年月日选择
    ChangePanel(mode) {
      this.monthShow = false;
      this.weekShow = false;
      this.dateShow = false;
      let show = mode + "Show";
      this[show] = true;
      // let today = new Date(new Date().setHours(0, 0, 0, 0)).getTime(); //当天0点的时间戳;
      //单个日期查询-- 切换年月日时，把原有的默认日期给清除
      this.queryItems.forEach((item) => {
        if (item.type == "dateOnly" || item.type == "dateOnlynoDay") {
          let range = this.queryItems.filter((el) => el.value === item.value);
          let value = range[0].value;
          this.queryParam[value] = "";
          console.log(mode, this.queryParam[value]);
        }
      });
      this.$emit("mode", mode);
    },
    TreeSelectChange(value, label, extra) {
      // console.log(value);
      // console.log(value.label);
      this.valueLabel = value.label;
      // console.log("子节点长度", extra.triggerNode.$parent);
      // this.treeSelectValue = value;
      // console.log(this.valueList);
      this.queryItems.forEach((item) => {
        if (item.type == "TreeSelect") {
          if (JSON.stringify(this.valueList).includes(JSON.stringify(value))) {
            // console.log(111);
            this.queryParam.bc_id = value.value;
            this.queryParam.cs_id = "";
          } else {
            // console.log(222);
            let cs_id = value.value.split("-");
            this.queryParam.cs_id = cs_id[1];
            this.queryParam.bc_id = extra.triggerNode.$parent.value;
          }
        }
      });
    },
    SelectChange(value, label) {
      // console.log("SelectChange", value, label);
      // // 如果选择无摄像头
      // if (label == "是否有摄像头" && value == "2") {
      //   // 1：有摄像头
      //   // 设备状态
      //   this.queryParam.device_status = "0";
      //   console.log("设备状态", this.queryParam.device_status);
      // }
      // // 如果选择设备状态为全部 则是否有摄像头为全部
      // if (label == "设备状态" && value == "0") {
      //   this.queryParam.camera = "";
      //   console.log("是否有摄像头", this.queryParam.camera);
      // } else if (label == "设备状态" && (value == "1" || value == "2")) {
      //   this.queryParam.camera = "1";
      //   console.log("是否有摄像头", this.queryParam.camera);
      // }
    },
    TreeSelect(value, label) {
      console.log(value);
      // console.log(label);
    },
    // 禁止选择今天以后的日期
    disabledDate_dataEnd(current) {
      // return current && current > moment().endOf("day");
      return current && current > moment().subtract(0, "days").endOf("day");
    },
    disabledDate_weekEnd(current) {
      return current && current > moment().subtract(0, "weeks").endOf("week");
    },
    disabledDate_monthEnd(current) {
      return current && current > moment().subtract(0, "months").endOf("month");
    },
    // 单个日期查询，不能选择今天以后的日期
    disabledDate_dataOnly(current) {
      // return current && current > moment().endOf("day");
      return current && current > moment().subtract(1, "days").endOf("day");
    },
    disabledDate_monthOnly(current) {
      // Can not select days before today and today
      // return current && current > moment().endOf(30, "day");
      return current && current > moment().subtract(1, "days").endOf("day");
    },
    disabledDate_weekOnly(current) {
      // return  current => current && current >= moment().startOf('week') && current <= moment().endOf('week')
      return current && current >= moment().startOf("week");
    },
    // 获取分公司id
    GetCompaniesId() {
      let CompanyListid = [];
      GetCompanies()
        .then((res) => {
          res.data.forEach((el) => {
            CompanyListid.push(el.bc_id);
          });
        })
        .then(() => {
          this.CompanyBcIds = CompanyListid.toString();
          console.log("CompanyListid", CompanyListid, this.CompanyBcIds);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.back {
  width: 30px;
  text-align: left;
  color: #ccc;
  text-decoration: underline;
  cursor: pointer;
}
// .search-default-value {
//   width: 200px;
// }
/deep/.BTNFlex {
  // background-color: pink;
  margin-top: 2%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}
.tipImg {
  display: flex;
  width: 50%;
  img {
    // z-index: 999;
    width: 15px;
    height: 15px;
    // margin-top: px;
    // overflow: "hidden";
    // background-color: pink;
  }
}
/deep/ .ant-tooltip-inner {
  min-width: 13.6vw;
}
.SiteNeat_date {
  display: flex;
  flex-wrap: nowrap;
  // background: pink;
  // width: 500px;
}
.dateRange_line {
  color: #ccc;
  padding: 0 3px;
}
.dateRange_Alert {
  position: absolute;
  width: 200px;
  z-index: 999;
  margin-top: -26px;
  margin-left: 250px;
  color: #ccc;
}
.dateRange_display {
  display: flex;
  flex-wrap: nowrap;
}

.datePick_out {
  display: flex;
  flex-wrap: nowrap;
  width: 82%;
  // margin-right: 0;
  .start_datePicker {
    width: 49%;
  }
}
.start_datePicker_only {
  width: 100%;
}
/deep/ .dateRangeBTN {
  padding: 2px !important;
  // margin-right: 1px;
}
.dateRangeBTNName {
  display: flex;
  flex-wrap: nowrap;
  // width: 18%;
}
</style>
